// Generated by Framer (716ed5b)

import { addFonts, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["C_J_md9c3"];

const serializationHash = "framer-0sW5C"

const variantClassNames = {C_J_md9c3: "framer-v-1v04s7l"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "C_J_md9c3", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Link href={{webPageId: "augiA20Il"}}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1v04s7l", className, classNames)} framer-1dn4loi`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"C_J_md9c3"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "24px"}}><motion.span style={{"--font-selector": "R0Y7SGVkdmlnIExldHRlcnMgU2Fucy1yZWd1bGFy", "--framer-font-family": "\"Hedvig Letters Sans\"", "--framer-font-size": "24px", "--framer-text-color": "var(--extracted-1w3ko1f, rgba(255, 255, 255, 1))"}}>Hotel</motion.span><motion.span style={{"--font-selector": "R0Y7Wm9kaWFrLXJlZ3VsYXI=", "--framer-font-family": "\"Zodiak\"", "--framer-font-size": "24px", "--framer-text-color": "var(--extracted-3sq8v0, rgba(255, 255, 255, 1))"}}>Beach.</motion.span></motion.p></React.Fragment>} className={"framer-1t2qxft"} data-framer-name={"HotelBeach."} fonts={["GF;Hedvig Letters Sans-regular", "GF;Zodiak-regular"]} layoutDependency={layoutDependency} layoutId={"YStl7jkNG"} style={{"--extracted-1w3ko1f": "rgba(255, 255, 255, 1)", "--extracted-3sq8v0": "rgba(255, 255, 255, 1)", "--framer-paragraph-spacing": "0px"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-0sW5C[data-border=\"true\"]::after, .framer-0sW5C [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0sW5C.framer-1dn4loi, .framer-0sW5C .framer-1dn4loi { display: block; }", ".framer-0sW5C.framer-1v04s7l { height: 30px; overflow: hidden; position: relative; text-decoration: none; width: 141px; }", ".framer-0sW5C .framer-1t2qxft { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 141
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerPgB2tkqkW: React.ComponentType<Props> = withCSS(Component, css, "framer-0sW5C") as typeof Component;
export default FramerPgB2tkqkW;

FramerPgB2tkqkW.displayName = "Logo";

FramerPgB2tkqkW.defaultProps = {height: 30, width: 141};

addFonts(FramerPgB2tkqkW, [{family: "Hedvig Letters Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/hedvigletterssans/v2/CHy_V_PfGVjobSBkihHWDT98RVp37w8jQJhN3Twgi1w.woff2", weight: "400"}])